<template>
    <div :class="['cs-radio-group', {disabled}]" :style="{ '--label-radio-width': width, '--label-inner-radio-width': innerWidth }">
        <div v-for="(item, key) in items" :key="key + 'label'"
             @click="checkRadio(key)">
            <!-- <input
                :id="`${radioName}radio${key}`"
                type="radio"
                :key="key + 'radio'"
                @click="checkRadio(key)"
            /> -->
            <label
                :for="`${radioName}radio${key}`"
                :class="{ active: String(value) === key }"
            ></label>
            <span>{{ item }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "CSRadio",
    props: {
        items: [Object, Array],
        radioName: String,
        width: String,
        innerWidth: String,
        value: {
            type: [String, Number, Boolean],
            default: "",
        },
      disabled: {
          type: Boolean,
          default: false,
      }
    },
    data() {
        return {};
    },
    methods: {
        checkRadio(val) {
            if (this.disabled) {
              return ;
            }
            if (['true', 'false'].includes(val)) {
              this.$emit("input",   val !== 'false');
              return ;
            }
            if (!isNaN(val)) {
              this.$emit("input", +val);
              return ;
            }
          this.$emit("input",   val);
        },
    },
};
</script>
<style lang="stylus" scoped>
.cs-radio-group
    display inline-block
    vertical-align middle
    line-height 1
    & > div
      display inline-block
      cursor pointer
      &:not(:last-child)
        margin-right 20px
    &.disabled
        cursor not-allowed
        label
            cursor not-allowed
    input[type='radio']
        display none
    label
        position relative
        width var(--label-radio-width, 14px)
        height var(--label-radio-width, 14px)
        border-radius 50%
        border 1px solid #1ab394
        box-sizing content-box
        vertical-align middle
        display inline-block
        margin-bottom 0
        cursor pointer
        &.active
            &::after
                position absolute
                content ' '
                width var(--label-inner-radio-width, 8px)
                height var(--label-inner-radio-width, 8px)
                background #1ab394
                border-radius 50%
                left 50%
                top 50%
                transform translateX(-50%) translateY(-50%)
    span
        margin-left 10px
        display inline-block
        vertical-align middle
</style>
